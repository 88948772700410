import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { IEnvironment, LangCode } from '@models';
import { EnvironmentLoadService } from './shared/services/environment-load.service';

const defaultLanguage: LangCode = 'en';

@Injectable({
  providedIn: 'root'
})
export class LangGuard implements CanActivate {

  constructor(
    private router: Router,
    private environmentLoadService: EnvironmentLoadService<IEnvironment>
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const validLanguages: LangCode[] = this.environmentLoadService.environment.STORES.map(item => item.region);
    const requestedLanguage: LangCode = route.params.language as LangCode;

    if (!validLanguages.includes(requestedLanguage)) {
      const languageFromReferrer: LangCode | null = this.getLanguageFromReferrer() as LangCode;
      const fallbackLanguage = languageFromReferrer && validLanguages.includes(languageFromReferrer)
        ? languageFromReferrer
        : defaultLanguage;

      this.router.navigate([fallbackLanguage, '404']);
      return false;
    }

    return true;
  }

  private getLanguageFromReferrer(): string | null {
    const referer = document.referrer;
    if (!referer) return null;

    try {
      const urlObj = new URL(referer);
      const pathParts = urlObj.pathname.split('/').filter(Boolean);
      return pathParts.length > 0 ? pathParts[0] : null;
    } catch (error) {
      return null;
    }
  }
}
